<template>
  <div class="pageContol studentList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">学员档案</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 10px">
            <div title="学员姓名" class="searchboxItem ci-full">
              <span class="itemLabel">学员姓名:</span>
              <el-input
                class="listInput"
                v-model="searchData.userName"
                placeholder="请输入学员姓名"
                size="small"
                clearable
              />
            </div>
            <div title="身份证号" class="searchboxItem ci-full">
              <span class="itemLabel">身份证号:</span>
              <el-input
                class="listInput"
                v-model="searchData.idcard"
                placeholder="请输入身份证号"
                size="small"
                clearable
              />
            </div>
            <div title="手机号" class="searchboxItem ci-full">
              <span class="itemLabel">手机号:</span>
              <el-input
                class="listInput"
                v-model="searchData.mobile"
                placeholder="请输入手机号"
                size="small"
                clearable
              />
            </div>
           
          </div>
          <div class="searchbox" style="margin-bottom: 10px">
             <div title="档案类型" class="searchboxItem ci-full itemLabel_study">
              <span class="itemLabel">档案类型:</span>
              <el-select
                v-model="searchData.studentType"
                placeholder="请选择档案类型"
                size="small"
              >
                <el-option
                  v-for="item in archiveTypeData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="培训地区" class="searchboxItem ci-full">
              <span class="itemLabel">培训地区:</span>
              <el-cascader
                :options="areatreeList"
                clearable
                filterable
                :props="propsarea"
                size="small"
                v-model="searchData.areaId"
              ></el-cascader>
            </div>
             <div title="监管状态" class="searchboxItem ci-full el-selectBox">
              <span class="itemLabel">监管状态:</span>
              <el-select
                v-model="searchData.sxSuperviseState"
                placeholder="请选择"
                multiple
                collapse-tags
                size="small"
              >
                <el-option
                  v-for="item in sxSuperviseStateData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox" style="margin-bottom: 10px">
             <div title="发证日期" class="searchboxItem ci-full">
              <span class="itemLabel">发证日期:</span>
              <el-date-picker
                v-model="searchData.certDate"
                type="daterange"
                size="small"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              />
            </div>
            <div title="结业日期" class="searchboxItem ci-full">
              <span class="itemLabel">结业日期:</span>
              <el-date-picker
                v-model="searchData.gradDate"
                type="daterange"
                size="small"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              />
            </div>
            
          </div>
          <div class="searchbox">
            <div title="入班日期" class="searchboxItem ci-full">
              <span class="itemLabel">入班日期:</span>
              <el-date-picker
                v-model="searchData.inClassDate"
                type="daterange"
                size="small"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              />
            </div>
            <div class="df" style="padding: 0 15px">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
              <el-button
                type="primary"
                class="bgc-bv"
                round
                @click="downloadStudentRoster()"
                >下载学员花名册</el-button
              >
              <!-- <el-button
                type="primary"
                class="bgc-bv"
                round
                @click="batchDownloadStudentFiles()"
                >批量下载学员档案</el-button
              > -->
              <el-button
                type="primary"
                class="bgc-bv"
                round
                @click="batchDownloadStudentFiles()"
                >下载档案</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
              />
              <el-table-column
                label="学员姓名"
                align="center"
                show-overflow-tooltip
                prop="userName"
                minWidth="100"
              />
              <el-table-column
                label="身份证号"
                align="center"
                show-overflow-tooltip
                prop="idcard"
                minWidth="180"
              />
              <el-table-column
                label="手机号"
                align="center"
                show-overflow-tooltip
                prop="mobile"
                minWidth="120"
              />
              <el-table-column
                label="学员类型"
                align="center"
                show-overflow-tooltip
                prop="studentType"
                minWidth="120"
              />
              <el-table-column
                label="培训地区"
                align="left"
                prop="areaName"
                show-overflow-tooltip
                min-width="150"
              />
              <el-table-column
                label="监管状态"
                align="center"
                show-overflow-tooltip
                prop="studentType"
                minWidth="120"
              >
                <template slot-scope="scope">
                  {{
                    $setDictionary(
                      "SX_SUPERVISE_STATE",
                      scope.row.sxSuperviseState
                    )
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="证书编号"
                align="center"
                show-overflow-tooltip
                prop="certificateNO"
                minWidth="120"
              />
              <el-table-column
                label="完成学时"
                align="center"
                show-overflow-tooltip
                prop="completeClasHours"
              />
              <el-table-column
                label="入班日期"
                align="center"
                show-overflow-tooltip
                prop="inClassDateTime"
                minWidth="120"
              >
                <template slot-scope="scope">
                  {{ scope.row.inClassDateTime | momentDate }}
                </template>
              </el-table-column>
              <el-table-column
                label="领劵日期"
                align="center"
                show-overflow-tooltip
                prop="couponDate"
                minWidth="120"
              >
                <template slot-scope="scope">
                  {{ scope.row.couponDate | momentDate }}
                </template>
              </el-table-column>
              <el-table-column
                label="结业时间"
                align="center"
                show-overflow-tooltip
                prop="gradDateTime"
                minWidth="120"
              >
                <template slot-scope="scope">
                  {{ scope.row.gradDateTime | momentDate }}
                </template>
              </el-table-column>
              <el-table-column
                label="培训劵名称"
                align="left"
                prop="shanxiCouponName"
                show-overflow-tooltip
                min-width="180"
              />
              <el-table-column
                label="培训劵编号"
                align="left"
                prop="shanxiCouponCode"
                show-overflow-tooltip
                min-width="150"
              />
              <el-table-column
                label="发证时间"
                align="center"
                show-overflow-tooltip
                prop="certDateTime"
                minWidth="120"
              >
                <template slot-scope="scope">
                  {{ scope.row.certDateTime | momentDate }}
                </template>
              </el-table-column>
              <el-table-column
                label="职业/工种"
                align="center"
                show-overflow-tooltip
                prop="occupationOrIndustry"
                minWidth="120"
              />
              <el-table-column
                label="操作"
                align="center"
                width="200px"
                fixed="right"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    size="mini"
                    @click="
                      lookCertificate(
                        scope.row.userId,
                        scope.row.projectId,
                        'pdf'
                      )
                    "
                    >合格证预览</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    style=""
                    @click="
                      lookArchives(scope.row.userId, scope.row.projectId, 'pdf')
                    "
                    >档案预览</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      class="dialog2"
      :title="dialogTitle"
      :visible.sync="dialog1"
      width="50%"
      top="15px"
      center
    >
      <div id="pdf-content" style="height: 800px" v-if="stu == 'pdf'"></div>
    </el-dialog>
    <el-dialog
      title="培训档案选择"
      :visible.sync="docsDialogVisible"
      width="40%"
      top="5%"
      :center="true"
      :before-close="closeDocsDialog"
    >
      <div style="height: 500px">
        <div class="ovy-a">
          <div class="downloadStyle">
            <el-form
              ref="docsFormall"
              :model="docsFormall"
              class="docsDialog"
              v-show="docsOptionally.length"
            >
              <el-form-item
                v-for="(item, index) in docsOptionally"
                :key="index"
              >
                <el-checkbox v-model="docsFormall[item.dictCode]">{{
                  item.dictValue
                }}</el-checkbox>
              </el-form-item>
            </el-form>
          </div>
          <el-checkbox v-model="agreeState" style="margin: 0 3%">
            <span style="color: red !important">
              根据有关部门业务开展需要，相关数据会涉及个人信息、照片等敏感信息，导出数据仅限于业务范围内使用，
              不得用于其他用途，如因个人原因导致数据泄露，由个人承担法律责任。我已阅读并同意上述内容。
            </span>
          </el-checkbox>
        </div>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
        style="display: flex; justify-content: center"
      >
        <div>
          <el-button class="bgc-bv" @click="confirmDocsDialog">确定</el-button>
          <el-button @click="closeDocsDialog">取消</el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>
<script>
const className = "eduCertService";
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import pdf from "pdfobject";
import { mapGetters } from "vuex";
export default {
  name: "trainingManagement/TrainingRecordsSee",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      searchData: {
        userName: "", //姓名
        idcard: "", //身份证号
        mobile: "", //手机号
        studentType: "70", //档案类型
        certDate: "", //发证时间
        gradDate: "", //发证时间
        inClassDate: "", //领劵时间
        sxSuperviseState: [],
        areaId:'', //行政区划
      },
      archiveTypeData: [
        {
          value: "70",
          label: "脱贫劳动力",
        },
        {
          value: "",
          label: "普惠制",
        },
      ],
      dialog1: false,
      ImgSrc: "",
      dialogTitle: "",
      docsDialogVisible: false,
      docsOptionally: [],
      docsFormall: {},
      agreeState: false, //弹框是否同意
      sxSuperviseStateData: [], //监管学员状态
      areatreeList: [],
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
    };
  },
  created() {
    this.getareatree()
    this.SX_SUPERVISE_STATE();
    this.getTableHeight();
  },
  watch: {},
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  mounted() {},
  methods: {
    /**
     *  行政区划
     */
    getareatree() {
      this.$post("/sys/area/tree")
        .then((res) => {
          this.areatreeList = res.data || [];
        })
        .catch(() => {
          return;
        });
    },
    //监管学员状态
    SX_SUPERVISE_STATE() {
      const sxSuperviseStateData = this.$setDictionary(
        "SX_SUPERVISE_STATE",
        "list"
      );
      for (const key in sxSuperviseStateData) {
        this.sxSuperviseStateData.push({
          value: key,
          label: sxSuperviseStateData[key],
        });
      }
    },
    //下载学员花名册
    downloadStudentRoster() {
      const params = {
        studentType: this.searchData.studentType,
        sxSuperviseState: this.searchData.sxSuperviseState,
      };
      if (this.searchData.userName) {
        params.userName = this.searchData.userName;
      }
      if (this.searchData.idcard) {
        params.idcard = this.searchData.idcard;
      }
      if (this.searchData.areaId) {
        params.areaId = this.searchData.areaId;
      }
      if (this.searchData.mobile) {
        params.mobile = this.searchData.mobile;
      }
      if (this.searchData.certDate) {
        params.certDateFront = this.searchData.certDate[0];
        params.certDateAfter = this.searchData.certDate[1];
      }
      if (this.searchData.gradDate) {
        params.gradDateFront = this.searchData.gradDate[0];
        params.gradDateAfter = this.searchData.gradDate[1];
      }
      if (this.searchData.inClassDate) {
        params.inClassDateFront = this.searchData.inClassDate[0];
        params.inClassDateAfter = this.searchData.inClassDate[1];
      }
      this.$post("/agency/ma-agency-student/exportMaAgencyRoster", params).then(
        (re) => {
          console.log(re);
          if (re.status == "0") {
            let list = [];
            list.push(re.data);
            for (let item of list) {
              if (!this.downloadItems.includes(item.taskId)) {
                this.$store.dispatch("pushDownloadItems", item.taskId);
              } else {
                this.$message.warning(
                  "[" + item.fileName + "]已经申请下载,请耐心等待"
                );
              }
            }
          } else {
            this.$message.error(re.message);
          }
        }
      );
    },
    //批量下载学员档案
    batchDownloadStudentFiles() {
      this.docsDialogVisible = true;
      this.$post("/biz/mercantile/agency/archives", {
        studentType: this.searchData.studentType,
      }).then((ret) => {
        console.log(ret);
        if (ret.status == "0") {
          this.docsOptionally = ret.data;
        }
      });
    },
    //查看档案
    lookArchives(userId, projectId, stu) {
      this.stu = stu;
      this.dialogTitle = "查看档案";
      this.$post("/cert/downloadTrainDocument", { userId, projectId }).then(
        (ret) => {
          if (ret.status == 0) {
            if (ret.data) {
              this.dialog1 = true;
              this.$nextTick(() => {
                pdf.embed(ret.data, "#pdf-content");
              });
            } else {
              this.$message({
                message: "暂无档案查看",
                type: "error",
              });
            }
          } else {
            this.$message({
              message: "暂无档案查看",
              type: "error",
            });
          }
        }
      );
    },
    confirmDocsDialog() {
      if (!this.agreeState) {
        this.$message({
          type: "warning",
          message: "请先阅读提示内容并同意",
        });
        return;
      }
      let params = {
        fileDTOS: [],
      };
      for (let i in this.docsFormall) {
        if (this.docsFormall[i]) {
          params.fileDTOS.push({
            fileType: i,
            fileName: this.docsOptionally.find((el) => el.dictCode == i)
              .dictValue,
            // className: className,
            personage: false,
          });
        }
      }
      const jsonPara = {
        studentType: this.searchData.studentType,
        sxSuperviseState: this.searchData.sxSuperviseState,
      };
      if (this.searchData.userName) {
        jsonPara.userName = this.searchData.userName;
      }
      if (this.searchData.idcard) {
        jsonPara.idcard = this.searchData.idcard;
      }
       if (this.searchData.areaId) {
        jsonPara.areaId = this.searchData.areaId;
      }
      if (this.searchData.mobile) {
        jsonPara.mobile = this.searchData.mobile;
      }
      if (this.searchData.certDate) {
        jsonPara.certDateFront = this.searchData.certDate[0];
        jsonPara.certDateAfter = this.searchData.certDate[1];
      }
      if (this.searchData.gradDate) {
        jsonPara.gradDateFront = this.searchData.gradDate[0];
        jsonPara.gradDateAfter = this.searchData.gradDate[1];
      }
      if (this.searchData.inClassDate) {
        jsonPara.inClassDateFront = this.searchData.inClassDate[0];
        jsonPara.inClassDateAfter = this.searchData.inClassDate[1];
      }
      params.jsonPara = JSON.stringify(jsonPara);
      if (!params.fileDTOS.length) {
        this.$message.error("请至少选择一项进行打印");
        return;
      }

      // if (!params.fileDTOSAll.length) {
      //   this.$message.error("请至少选择一项进行打印");
      //   return;
      // }

      this.$post("/sys/download/maAgency/asyncDownloadFile", params).then(
        (re) => {
          if (re.status == "0") {
            let list = re.data;
            for (let item of list) {
              if (!this.downloadItems.includes(item.taskId)) {
                this.$store.dispatch("pushDownloadItems", item.taskId);
              } else {
                this.$message.warning(
                  "[" + item.fileName + "]已经申请下载,请耐心等待"
                );
              }
            }
            this.closeDocsDialog();
          } else {
            this.$message.error(re.message);
          }
        }
      );
    },
    closeDocsDialog() {
      this.docsDialogVisible = false;
      this.agreeState = false;
      this.$refs["docsFormall"].resetFields();
      for (let key in this.docsFormall) {
        this.docsFormall[key] = false;
      }
    },
    //查看合格证
    lookCertificate(userId, projectId, stu) {
      this.stu = stu;
      this.dialogTitle = "查看合格证";
      this.$post("/cert/downloadCert", { userId, projectId }).then((ret) => {
        if (ret.status == 0) {
          if (ret.data) {
            this.dialog1 = true;
            this.$nextTick(() => {
              pdf.embed(ret.data, "#pdf-content");
            });
          } else {
            this.$message({
              message: "暂无合格证",
              type: "error",
            });
          }
        } else {
          this.$message({
            message: "暂无合格证",
            type: "error",
          });
        }
      });
    },
    getData(pageNum = 1) {
      const params = {
        // projectId: this.$route.query.projectId,
        pageNum: pageNum,
        pageSize: this.pageSize,
        studentType: this.searchData.studentType,
        sxSuperviseState: this.searchData.sxSuperviseState,
      };
      if (this.searchData.userName) {
        params.userName = this.searchData.userName;
      }
      if (this.searchData.idcard) {
        params.idcard = this.searchData.idcard;
      }
       if (this.searchData.areaId) {
        params.areaId = this.searchData.areaId;
      }
      if (this.searchData.mobile) {
        params.mobile = this.searchData.mobile;
      }
      if (this.searchData.certDate) {
        params.certDateFront = this.searchData.certDate[0];
        params.certDateAfter = this.searchData.certDate[1];
      }
      if (this.searchData.gradDate) {
        params.gradDateFront = this.searchData.gradDate[0];
        params.gradDateAfter = this.searchData.gradDate[1];
      }
      if (this.searchData.inClassDate) {
        params.inClassDateFront = this.searchData.inClassDate[0];
        params.inClassDateAfter = this.searchData.inClassDate[1];
      }
      this.doFetch({
        url: "/agency/ma-agency-student/archivePageList",
        params,
        pageNum,
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight = window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 8) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>
<style lang="less" scope>
// .el-table--small td, .el-table--small th {
//   padding: 0
// }
.el-table {
  .el-table__fixed-right {
    height: auto !important; // 此处的important表示优先于element.style
    bottom: 12px; // 改为自动高度后，设置与父容器的底部距离，则高度会动态改变
  }
}
.el-upload-list--picture-card .el-upload-list__item {
  width: 64px;
  min-width: 64px;
  height: 82px;
  display: flex;
}

.el-upload-list.el-upload-list--picture-card {
  display: flex;
}
.el-select__tags-text {
  min-width: 2rem !important;
}
.el-selectBox {
  .el-select {
    // min-width: 15rem;
    .el-tag {
      min-width: 10px !important;
    }
  }
}
</style>
<style lang="less">
.hide .el-upload--picture-card {
  display: none;
}
.downloadStyle {
  background-color: #f0f5fb;
  padding: 20px 0;
  margin-bottom: 30px;
  overflow: hidden;
}
.docsDialog {
  .el-form-item {
    width: 44%;
    float: left;
    margin-bottom: 0.5rem;
    margin: 10px 3%;
  }
}
.el-checkbox {
  display: flex;
  white-space: pre-wrap !important;
  text-align: justify;
}
</style>
